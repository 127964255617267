import React from "react"
import { withStyles} from '@material-ui/core/styles';
import {TextField, Grid, Button, Typography} from '@material-ui/core';
import PropTypes from 'prop-types';
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const styles = theme => ({
    upData: {
        '& > *': {
            margin: theme.spacing(2)
          },
    },
    msg: {
        width: '81%',
    },
    btn: {
        textTransform: 'none',
        marginLeft: theme.spacing(2)
    },
    warningText:{
        paddingLeft: 5,
        color: 'red'
    }
  });

  class Contact extends React.Component  {
    constructor() {
        super();
        // No hagas esto!
        this.state = { 
          name: '',
          phone:'',
          email:'',
          body: '',
          messageAlert: '',
         };
       }

    render(){
        const { classes } = this.props;
        const sendMail=()=>{
            if( checkEmail(this.state.email) && checkPhone(this.state.phone)){
                if (this.state.body !=='' && this.state.name!==''){
                    const link = "mailto:soysitimm.ctm@gmail.com"
                            //+ "?cc="+this.state.email
                            + "?subject=" + escape("Contacto desde Página CEFORMA de "+this.state.name)
                            + "&body=" + escape('Envia: '+ this.state.name +'\nTelefono: '+this.state.phone+'\nCorreo: '+ this.state.email+'\n'+this.state.body)
                    ;
                
                    window.location.href = link;
                }else{
                    this.setState({messageAlert: 'Ingrese los datos faltantes.'})
                }
            }
        }
        const checkEmail= mail => {
          const regEmail = new RegExp(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i)
          if(!regEmail.test(mail)&& mail!==''){
            this.setState({messageAlert:'Ingrese un correo electrónico válido.'})
            return false
           }else{
            this.setState({email:mail , messageAlert:''})
            return true
            }
        }
        const checkPhone = phoneCk=> {
          const regTel = new RegExp(/^\d{10}$/)///\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/);
          if(!regTel.test(phoneCk)&& phoneCk!==''){
            this.setState({messageAlert:'Ingrese un teléfono válido.'});
            return false
            }else{
                this.setState({phone:phoneCk , messageAlert:''})
                return true
            }
        }
    
    return (
     <Layout>
     <SEO title="Contacto" />
     <h1>Contáctanos, nos interesa tu opinión</h1>
     <Grid>
        <Grid 
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          className={classes.upData}
        >
        <TextField id="outlined-basic" label="Nombre" variant="outlined" 
        onChange={(event)=>{this.setState({name: event.target.value})}} value={this.state.name}/>
        <TextField id="outlined-basic" label="Correo electrónico" variant="outlined" 
        onChange={(event)=>{this.setState({email:event.target.value})}} value={this.state.email}/>
        <TextField id="outlined-basic"  label="Teléfono" variant="outlined" 
        onChange={(event)=>{this.setState({phone: event.target.value})}} value={this.state.phone}/>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          className={classes.upData}
        >
        <TextField
          id="outlined-multiline-static"
          label="Mensaje"
          multiline
          rows={10}
          className={classes.msg}
          variant="outlined"
          onChange={(event)=>{this.setState({body:event.target.value})}} 
          value={this.state.body}
        />
        </Grid>
        <Grid
        container
        direction="row">
        <Button variant="contained" color="secondary" className={classes.btn}
        onClick={()=>{sendMail()}}>
            Enviar
        </Button> <Typography className={classes.warningText}>{this.state.messageAlert}</Typography>
        </Grid>
     </Grid>
   </Layout>
)};
}

Contact.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  export default withStyles(styles)(Contact)
  